/* Copyright (C) YOOtheme GmbH, YOOtheme Proprietary Use License (http://www.yootheme.com/license) */

(function($) {

    $(document).ready(function() {

        var config = $('body').data('config') || {};

        // Accordion menu
        $('.menu-sidebar').accordionMenu({
            mode: 'slide'
        });

        // Dropdown menu
        $('#menu').dropdownMenu({
            mode: 'slide',
            dropdownSelector: 'div.dropdown'
        });

        // Smoothscroller
        $('a[href="#page"]').smoothScroller({
            duration: 500
        });

        // Fix Browser Rounding
        $('.grid-block').matchWidth('.grid-h');

        // Social buttons
        $('article[data-permalink]').socialButtons(config);

        // Match height of div tags
        var matchHeight = function() {
            $('#top-a .grid-h').matchHeight('.deepest');
            $('#top-b .grid-h').matchHeight('.deepest');
            $('#bottom-a .grid-h').matchHeight('.deepest');
            $('#bottom-b .grid-h').matchHeight('.deepest');
            $('#bottom-c .grid-h').matchHeight('.deepest');
            $('#innertop .grid-h').matchHeight('.deepest');
            $('#innerbottom .grid-h').matchHeight('.deepest');
            $('#maininner, #sidebar-a, #sidebar-b').matchHeight();
            $('.wrapper').css("min-height", $(window).height());
        };

        matchHeight();

        $(window).bind("load", matchHeight);

        // ------------------------------------------------------------------------------------------

        // // sorter table

        // $(".set_tobe1").click(function() {
        //     $(".tobe1").toggle("slide");
        // });

        // $(".set_tobe2").click(function() {
        //     $(".tobe2").toggle("slide");
        // });

        // table sorter --------------------


        // ------------------------------------------------------------------------------------------


        // $(document).ready(function() {
        //     $("#myTable").tablesorter({
        //         // pass the headers argument and assing a object
        //         headers: {
        //             // assign the secound column (we start counting zero)
        //             3: {
        //                 // disable it by setting the property sorter to false
        //                 sorter: false
        //             },
        //             // assign the third column (we start counting zero)
        //             4: {
        //                 // disable it by setting the property sorter to false
        //                 sorter: false
        //             },
        //             // assign the third column (we start counting zero)
        //             5: {
        //                 // disable it by setting the property sorter to false
        //                 sorter: false
        //             }
        //         }
        //     });
        // });
        // // ------------------------------------------------------------------------------------------

        // $.vegas('slideshow', {
        //     delay: 4000,
        //     backgrounds: [

        //         {
        //             src: 'vegas/images/as_bg71.jpg',
        //             fade: 2000
        //         },

        //         {
        //             src: 'vegas/images/as_bg62.jpg',
        //             fade: 2000
        //         },



        //     ]
        // })('overlay', {
        //     src: 'vegas/overlays/07.png'
        // });




        // MixItUp---------------------------------------------------------------------------


        // On document ready:






        // var $filterSelect = $('#FilterSelect'),
        //     $sortSelect = $('#SortSelect'),
        //     $container = $('#Container').mixItUp({
        //               animation: {
        //                   enable:true,
        //       duration: 550,
        //       effects: 'fade translateX(10%) rotateX(20deg) stagger(44ms)',
        //       easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
        //                   animateResizeContainer: false,
        //               },

        //               layout: {
        //       display: 'block',
        //       containerClass: 'list',
        //       containerClassFail: 'altceva'
        //   }



        //   $filterSelect.on('change', function(){
        //     $container.mixItUp('filter', this.value);
        //   });

        //   $sortSelect.on('change', function(){
        //     $container.mixItUp('sort', this.value);
        //   });
        // });




        // $(function() {

        //     // Instantiate MixItUp:



        //         $container = $('#Container');


        //     $container.mixItUp({
        //         animation: {
        //             enable: true,
        //             duration: 640,
        //             effects: 'fade translateZ(-360px) translateX(-34%) rotateY(-50deg)',
        //             easing: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)'

        //         },

        //         layout: {
        //             display: 'block',


        //         }
        //     });


        // });




        // efecte---------------------------------------------------------------------------
        // $(document).ready(function() {
        //     $(".wrapper").hide().fadeIn(2000);
        // });

        // $(document).ready(function() {
        //     $("html ").hide().fadeIn(500);
        // });

        // ------------------------------------------------------------------------------------------


        // $("#sidebar-a").addClass("animated bounceInRight");
        // $("#maininner").addClass("animated bounceInLeft");
        // $(".logo").addClass("animated bounceInLeft");
        // $("#menubar").addClass("animated rotateIn");

        // ------------------------------------------------------------------------------------------



        // music instrments icons

        // $(document).ready(function() {
        //     $("<div class='drums'></div>").appendTo("li.item482");
        // });

        // $(document).ready(function() {
        //     $("<div class='conga'></div>").appendTo("li.item483");
        // });

        // $(document).ready(function() {
        //     $("<div class='guitar'></div>").appendTo("li.item484");
        // });

        // $(document).ready(function() {
        //     $("<div class='amp'></div>").appendTo("li.item485");
        // });

        // $(document).ready(function() {
        //     $("<div class='amp_bass'></div>").appendTo("li.item486");
        // });
        // $(document).ready(function() {
        //     $("<div class='key'></div>").appendTo("li.item487");
        // });

        // ------------------------------------------------------------------------------------------

        // $(document).ready(function() {
        //     $("li.item482").hover(function() {
        //         $(".drums").toggleClass("drums_hover animated rotateIn");
        //     });
        // });

        // $(document).ready(function() {
        //     $("li.item483").hover(function() {
        //         $(".conga").toggleClass("conga_hover animated rotateIn");
        //     });
        // });

        // $(document).ready(function() {
        //     $("li.item484").hover(function() {
        //         $(".guitar").toggleClass("guitar_hover animated rotateIn");
        //     });
        // });

        // $(document).ready(function() {
        //     $("li.item485").hover(function() {
        //         $(".amp").toggleClass("amp_hover animated rotateIn");
        //     });
        // });

        // $(document).ready(function() {
        //     $("li.item486").hover(function() {
        //         $(".amp_bass").toggleClass("amp_bass_hover animated rotateIn");
        //     });
        // });

        // $(document).ready(function() {
        //     $("li.item487").hover(function() {
        //         $(".key").toggleClass("key_hover animated rotateIn");
        //     });
        // });

        // ------------------------------------------------------------------------------------------

        // $(document).ready(function() {

        //     $(" .dw_stack").hover(function() {
        //         $(".dw_stack").toggleClass("active");

        //     });

        // });

        // $(document).ready(function() {

        //     $(" .gretsch_stack").hover(function() {
        //         $(".gretsch_stack").toggleClass("active");

        //     });

        // });


        // ------------------------------------------------------------------------------------------



        // ------------------------------------------------------------------------------------------

        $(document).ready(function() {

            $(".dw span:nth-child(2)").click(function() {
                $(".dw div").toggleClass("active");
            });



            $(".gretsch span:nth-child(2)").click(function() {
                $(".gretsch div").toggleClass("active");
            });



            $(".yamaha1 span:nth-child(2)").click(function() {
                $(".yamaha1 div").toggleClass("active");
            });



            $(".yamaha2 span:nth-child(2)").click(function() {
                $(".yamaha2 div").toggleClass("active");
            });


            $(".tama span:nth-child(2)").click(function() {
                $(".tama div").toggleClass("active");
            });


            $(".tama1 span:nth-child(2)").click(function() {
                $(".tama1 div").toggleClass("active");
            });


            $(".ludwig span:nth-child(2)").click(function() {
                $(".ludwig div").toggleClass("active");
            });


            $(".ludwig1 span:nth-child(2)").click(function() {
                $(".ludwig1 div").toggleClass("active");
            });



            $(".LP span:nth-child(2)").click(function() {
                $(".LP div").toggleClass("active");
            });

        });


        // ------------------------------------------------------------------------------------------



        // tobe------------------------------------------------------------------------------------------


        $("span:contains('DW')").parents(".itemContainer").addClass("category-1 mix");
        $("span:contains('GRETSCH')").parents(".itemContainer").addClass("category-2 mix ");
        $("span:contains('Yamaha')").parents(".itemContainer").addClass("category-3 mix ");
        $("span:contains('Tama')").parents(".itemContainer").addClass("category-4 mix ");
        $("span:contains('Ludwig')").parents(".itemContainer").addClass("category-5 mix ");

        // percutie------------------------------------------------------------------------------------------

        // filtru 1 prod

        $("span:contains('LP')").parents(".itemContainer").addClass("category-1 mix");
        $("span:contains('REMO')").parents(".itemContainer").addClass("category-2 mix ");
        $("span:contains('MEINL')").parents(".itemContainer").addClass("category-3 mix ");

        // filtru 2 categorii

        $("span:contains('CONGAS')").parents(".itemContainer").addClass("congas");
        $("span:contains('BONGOS')").parents(".itemContainer").addClass("bongos");
        $("span:contains('Timbales')").parents(".itemContainer").addClass("timbales");
        $("span:contains('Djembe')").parents(".itemContainer").addClass("djembe");
        $("span:contains('DARBUKA')").parents(".itemContainer").addClass("darbuka");
        $("span:contains('Rototoms')").parents(".itemContainer").addClass("rototoms");
        $("span:contains('Triangle')").parents(".itemContainer").addClass("triangle");
        $("span:contains('Cabassa')").parents(".itemContainer").addClass("cabassa");
        $("span:contains('Shaker')").parents(".itemContainer").addClass(" shaker");
        $("span:contains('COWBELLS')").parents(".itemContainer").addClass(" cowbells");

        // filtru 1 seturi

        $("span:contains('805Z')").parents(".itemContainer").addClass("set-congas");
        $("span:contains('806Z')").parents(".itemContainer").addClass("set-congas");
        $("span:contains('807Z')").parents(".itemContainer").addClass("set-congas");


        $("span:contains('204a')").parents(".itemContainer").addClass("set-cowbells");
        $("span:contains('406A')").parents(".itemContainer").addClass("set-cowbells");

        $("span:contains('441')").parents(".itemContainer").addClass("set-shakers");
        $("span:contains('462B')").parents(".itemContainer").addClass("set-shakers");
        $("span:contains('440')").parents(".itemContainer").addClass("set-shakers");

        // chitari------------------------------------------------------------------------------------------

        // filtru 1 producatori

        $("span:contains('fender')").parents(".itemContainer").addClass("mix fender-cat");
        $("span:contains('ephiphone')").parents(".itemContainer").addClass("mix ephiphone");
        $("span:contains('gibson')").parents(".itemContainer").addClass("mix gibson");
        $("span:contains('kay')").parents(".itemContainer").addClass("mix kay");
        $("span:contains('takamine')").parents(".itemContainer").addClass("mix takamine");


        // filtru 2 categorii
        $("span:contains('acustica')").parents(".itemContainer").addClass("acustica");
        $("span:contains('electrica')").parents(".itemContainer").addClass("electrica");
        $("span:contains('bass')").parents(".itemContainer").addClass("bass");
        $("span:contains('contrabass')").parents(".itemContainer").addClass("contrabass");

        // amp chitari------------------------------------------------------------------------------------------

        // filtru 1 producatori

        $("span:contains('MARSHALL')").parents(".itemContainer").addClass("mix marshall");
        $("span:contains('ROLAND')").parents(".itemContainer").addClass("mix roland");
        $("span:contains('VOX')").parents(".itemContainer").addClass("mix vox");
        $("span:contains('TRACE')").parents(".itemContainer").addClass("mix trace");
        $("span:contains('FENDER')").parents(".itemContainer").addClass("mix fender-cat");
        $("span:contains('MESA')").parents(".itemContainer").addClass("mix mesa");

        // filtru 2 categorii

        $("span:contains('Combo Ac')").parents(".itemContainer").addClass("combo-ac");
        $(".combo span:contains('Combo')").parents(".itemContainer").addClass("combo");
        $("span:contains('Combo TWIN')").parents(".itemContainer").addClass("combo-twin");
        $("span:contains('Combo Hot')").parents(".itemContainer").addClass("combo-hot");
        $("span:contains('Boxe Chitara')").parents(".itemContainer").addClass("boxe-chitara");
        $("span:contains('Amplificator Chitara Acustica')").parents(".itemContainer").addClass("amp-chitara-ac");
        $(".amp-chit span:contains('Amplificator Chitara')").parents(".itemContainer").addClass("amp-chitara");

        // amp bass------------------------------------------------------------------------------------------

        // filtru 1 producatori

        $("span:contains('AMPEG')").parents(".itemContainer").addClass("mix ampeg");
        $("span:contains('ASHDOWN')").parents(".itemContainer").addClass("mix ashdown");
        $("span:contains('EDEN')").parents(".itemContainer").addClass("mix eden");
        $("span:contains('FENDER')").parents(".itemContainer").addClass("mix fender-cat");
        $("span:contains('TRACE')").parents(".itemContainer").addClass("mix trace");
        $("span:contains('Swr')").parents(".itemContainer").addClass("mix swr");
        $("span:contains('MESA')").parents(".itemContainer").addClass("mix mesa");
        $("span:contains('GALLIEN')").parents(".itemContainer").addClass("mix gallien");


        // filtru 2 categorii

        $("span:contains('Aplificator Bass')").parents(".itemContainer").addClass("amp-bass");
        $("span:contains('Boxe Bass')").parents(".itemContainer").addClass("boxe-bass");
        $("span:contains('Combo Bass')").parents(".itemContainer").addClass("combo-bass");


        // keyboards------------------------------------------------------------------------------------------

        // filtru 1 producatori

        $("span:contains('M-AUDIO')").parents(".itemContainer").addClass("mix audio");
        $("span:contains('AKAI')").parents(".itemContainer").addClass("mix akai");
        $("span:contains('MOOG')").parents(".itemContainer").addClass("mix moog");
        $("span:contains('FENDER')").parents(".itemContainer").addClass("mix fender-cat");
        $("span:contains('HAMMOND')").parents(".itemContainer").addClass("mix hammond");
        $("span:contains('KORG')").parents(".itemContainer").addClass("mix korg");
        $("span:contains('YAMAHA')").parents(".itemContainer").addClass("mix yamaha");
        $("span:contains('ROLAND')").parents(".itemContainer").addClass("mix roland");
        $("span:contains('KURZWEIL')").parents(".itemContainer").addClass("mix kurzweil");
        $("span:contains('CLAVIA')").parents(".itemContainer").addClass("mix clavia");


        // filtru 2 categorii

        $("span.master:contains('Master Key')").parents(".itemContainer").addClass("master-key");
        $("span.keyboard:contains('Keyboards')").parents(".itemContainer").addClass("keyboards");
        $("span:contains('Synth')").parents(".itemContainer").addClass("synth");
        $("span:contains('Electric Piano')").parents(".itemContainer").addClass("electric");
        $("span:contains('Orga')").parents(".itemContainer").addClass("orga");
        $("span:contains('Stage')").parents(".itemContainer").addClass("stage");

        // ------------------------------------------------------------------------------------------


        // $(".itemsCategory p ").css("background", "red");


        $(".dimensiuni p ").css("display", "block");


        $(".disparut").css("display", "none");
        // $("div.container").append("<div class='coltar'></div>");

        // Linkuri tobe-----------------------------------------------------------------------------------------

        tobe = [19, 18, 17, 16, 15, 14, 13, 12];
        $("span:contains('DW')").parents(".itemContainer").addClass("item-tobe");
        $("div.item-tobe").siblings('div').addClass("item-tobe");


        $(".item-tobe:nth-child(1) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[0] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(2) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[1] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(3) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[2] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(4) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[3] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(5) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[4] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(6) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[5] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(7) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[6] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(8) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[7] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(9) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[8] + '&lang=en&view=item"></a>');
        $(".item-tobe:nth-child(10) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + tobe[9] + '&lang=en&view=item"></a>');

        // Linkuri percutie-----------------------------------------------------------------------------------------

        percutie = [38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, ];
        $("span:contains('LP')").parents(".itemContainer").addClass("item-percutie");
        $("div.item-percutie").siblings('div').addClass("item-percutie");


        $(".item-percutie:nth-child(1) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[0] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(2) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[1] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(3) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[2] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(4) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[3] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(5) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[4] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(6) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[5] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(7) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[6] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(8) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[7] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(9) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[8] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(10) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[9] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(11) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[10] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(12) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[11] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(13) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[12] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(14) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[13] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(15) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[14] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(16) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[15] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(17) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[16] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(18) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[17] + '&lang=en&view=item"></a>');
        $(".item-percutie:nth-child(19) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + percutie[18] + '&lang=en&view=item"></a>');

        // Linkuri chitari-----------------------------------------------------------------------------------------

        chitari = [52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41, 40, 39, ];
        $("span:contains('phiphone')").parents(".itemContainer").addClass("item-chitari");
        $("div.item-chitari").siblings('div').addClass("item-chitari");


        $(".item-chitari:nth-child(1) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[0] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(2) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[1] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(3) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[2] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(4) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[3] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(5) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[4] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(6) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[5] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(7) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[6] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(8) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[7] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(9) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[8] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(10) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[9] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(11) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[10] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(12) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[11] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(13) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[12] + '&lang=en&view=item"></a>');
        $(".item-chitari:nth-child(14) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + chitari[13] + '&lang=en&view=item"></a>');

        // Linkuri Amp Chitari-----------------------------------------------------------------------------------------

        amp_chitari = [82, 81, 80, 79, 78, 77, 76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63, 62, 61, 60, 59, 58, 57, 56, 55, 54, 53];
        $("span:contains('VOX')").parents(".itemContainer").addClass("item-amp_chitari");
        $("div.item-amp_chitari").siblings('div').addClass("item-amp_chitari");


        $(".item-amp_chitari:nth-child(1) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[0] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(2) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[1] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(3) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[2] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(4) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[3] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(5) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[4] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(6) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[5] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(7) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[6] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(8) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[7] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(9) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[8] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(10) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[9] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(11) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[10] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(12) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[11] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(13) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[12] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(14) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[13] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(15) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[14] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(16) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[15] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(17) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[16] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(18) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[17] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(19) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[18] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(20) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[19] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(21) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[20] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(22) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[21] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(23) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[22] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(24) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[23] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(25) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[24] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(26) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[25] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(27) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[26] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(28) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[27] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(29) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[28] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari:nth-child(30) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari[29] + '&lang=en&view=item"></a>');

        // Linkuri Amp Chitari Bass-----------------------------------------------------------------------------------------

        amp_chitari_bass = [106, 105, 104, 103, 102, 101, 100, 99, 98, 97, 96, 95, 94, 93, 92, 91, 90, 89, 88, 87, 86, 85, 84, 83, ];
        $("span:contains('ASHDOWN')").parents(".itemContainer").addClass("item-amp_chitari_bass");
        $("div.item-amp_chitari_bass").siblings('div').addClass("item-amp_chitari_bass");


        $(".item-amp_chitari_bass:nth-child(1) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[0] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(2) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[1] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(3) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[2] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(4) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[3] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(5) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[4] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(6) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[5] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(7) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[6] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(8) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[7] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(9) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[8] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(10) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[9] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(11) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[10] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(12) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[11] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(13) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[12] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(14) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[13] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(15) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[14] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(16) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[15] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(17) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[16] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(18) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[17] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(19) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[18] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(20) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[19] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(21) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[20] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(22) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[21] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(23) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[22] + '&lang=en&view=item"></a>');
        $(".item-amp_chitari_bass:nth-child(24) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + amp_chitari_bass[23] + '&lang=en&view=item"></a>');


        // Linkuri Keyboards-----------------------------------------------------------------------------------------

        keyboards = [145, 144, 143, 142, 141, 140, 139, 138, 137, 136, 135, 134, 133, 132, 131, 130, 129, 128, 127, 126, 125, 124, 123, 122, 121, 120, 119, 118, 117, 116, 115, 114, 113, 112, 111, 110, 109, 108, 107, ];
        $("span:contains('CLAVIA')").parents(".itemContainer").addClass("item-keyboards");
        $("div.item-keyboards").siblings('div').addClass("item-keyboards");


        $(".item-keyboards:nth-child(1) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[0] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(2) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[1] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(3) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[2] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(4) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[3] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(5) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[4] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(6) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[5] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(7) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[6] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(8) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[7] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(9) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[8] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(10) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[9] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(11) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[10] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(12) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[11] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(13) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[12] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(14) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[13] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(15) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[14] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(16) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[15] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(17) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[16] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(18) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[17] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(19) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[18] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(20) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[19] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(21) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[20] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(22) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[21] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(23) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[22] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(24) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[23] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(25) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[24] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(26) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[25] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(27) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[26] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(28) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[27] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(29) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[28] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(30) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[29] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(31) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[30] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(32) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[31] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(33) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[32] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(34) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[33] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(35) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[34] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(36) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[35] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(37) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[36] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(38) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[37] + '&lang=en&view=item"></a>');
        $(".item-keyboards:nth-child(39) * .coltar").wrap('<a href="index.php?option=com_k2&Itemid=482&id=' + keyboards[38] + '&lang=en&view=item"></a>');


        // adaugare coltar-----------------------------------------------------------------------------------------

        $(".imagini").append('<div></div>');
        $("#menubar").append('<div class="logo-bg"></div>');

        // stergere malware-----------------------------------------------------------------------------------------

        $("p:contains('Bookame')").parent().remove();

        // google maps

        function initialize() {
            var mapCanvas = document.getElementById('map_canvas');
            var myLatlng = new google.maps.LatLng(44.415365, 26.120911);
            var mapOptions = {


                center: new google.maps.LatLng(44.415365, 26.120911),
                zoom: 15,
                panControl: false,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                overviewMapControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };


            var map = new google.maps.Map(mapCanvas, mapOptions);
            var image = {
                url: "../templates/as2/images/audiosolution/audiosolution_logo1.png",

                // This marker is 20 pixels wide by 32 pixels tall.



            };

            // To add the marker to the map, use the 'map' property
            var marker = new google.maps.Marker({
                position: myLatlng,
                map: map,
                title: "Audiosolution",
                icon: image,
            });
        }

        google.maps.event.addDomListener(window, 'load', initialize);


        var slider = new MasterSlider();
        slider.setup('masterslider', {
            width: 730, // slider standard width
            height: 350, // slider standard height
            swipe: false,
            mouse: false,
            wheel: true,
            autoplay: true,
            dir: "h",
            view: "fade",

        });

        var slider = new MasterSlider();
        slider.setup('masterslider_despre', {
            width: 218, // slider standard width
            height: 180, // slider standard height
            loop: true,
            mouse: false,
            speed: 1,
            wheel: false,
            autoplay: true,
            view: "fade",
            grabCursor: false


        });
        var slider = new MasterSlider();
        slider.setup('masterslider_marci', {
            loop: true,
            width: 80,
            height: 60,
            speed: 1,
            view: 'focus',
            mouse: false,
            autoplay: true,
            preload: 10,
            space: 10,
            wheel: true,
            grabCursor: false
        });

                var slider = new MasterSlider();
        slider.setup('masterslider_foto', {
            width: 139, // slider standard width
            height: 100, // slider standard height
            loop: true,
            mouse: false,
            speed: 10,
            wheel: false,
            autoplay: true,
            view: "fade",
            grabCursor: false


        });


                var slider = new MasterSlider();
        slider.setup('ms_despre_multi', {
            width: 400, // slider standard width
            height: 300, // slider standard height
            loop: true,
            mouse: false,
            speed: 50,
            wheel: false,
            autoplay: true,
            view: "focus",
            grabCursor: false


        });



        // css edit

        // $( "div" ).find( ".acoustic" ).css( "background-color", "red" );
        // $( "div" ).find( ".electric" ).css( "background-color", "green" );
        // $( "div" ).find( ".bass" ).css( "background-color", "blue" );
        $(".acoustic").insertAfter('.top_title:nth-of-type(5)');
        $(".electric").insertAfter('.top_title:nth-of-type(3)');
        $(".bass").insertAfter('.top_title:nth-of-type(5)');

        // end of function -----------write above this------------------------------
    });




})(jQuery);
